import useMediaQuery from '@/hooks/useMediaQuery'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import {
  ForwardedRef,
  HTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from 'react'
import styled from 'styled-components'

const RenderOnlyPc = forwardRef(
  (
    { children, ...rest }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { isLoading, matches: isPc } = useMediaQuery(
      `(min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER})`
    )
    return (
      (isLoading || isPc) && (
        <Styled.PcWrap
          ref={ref}
          style={{ display: isLoading && 'none' }}
          {...rest}
        >
          {children}
        </Styled.PcWrap>
      )
    )
  }
)
RenderOnlyPc.displayName = 'RenderOnlyPc'

const RenderOnlyMobile = forwardRef(
  (
    { children, ...rest }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { matches: isMobile, isLoading } = useMediaQuery(
      `(max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH})`
    )
    return (
      (isLoading || isMobile) && (
        <Styled.MobileWrap
          ref={ref}
          style={{ display: isLoading && 'none' }}
          {...rest}
        >
          {children}
        </Styled.MobileWrap>
      )
    )
  }
)
RenderOnlyMobile.displayName = 'RenderOnlyMobile'

const RenderOnly = {
  Pc: RenderOnlyPc,
  Mobile: RenderOnlyMobile,
}

const Styled = {
  PcWrap: styled.div`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: none !important;
    }
  `,

  MobileWrap: styled.div`
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      display: none !important;
    }
  `,
}

export default RenderOnly
