import React, {
  HTMLAttributes,
  PropsWithChildren,
  useCallback,
  useRef,
} from 'react'
import { ComponentStyledOptionalProps } from '@/types/Component'
import Link from 'next/link'
import { UrlObject } from 'url'
import RouterMap from '@/constants/RouterMap'
import styled from 'styled-components'
import {
  AnchorTargetType,
  ContentLogEventActionName,
  ContentLogPageName,
} from '@/enums/Content'
import { trackEvent } from '@/helpers/logger'
import { isArray, isString } from 'lodash-es'
import { convertTextWithHidden } from '@/helpers/hidden'
import { Hidden } from '@kakaomobility/tui-react'

interface LinkButtonProps
  extends ComponentStyledOptionalProps,
    HTMLAttributes<any> {
  href?: string | UrlObject
  pathname?: string
  outLink?: boolean
  innerLink?: boolean
  plainText?: boolean
  autoBlur?: boolean
  onClick?: (...args: any[]) => any
  target?: AnchorTargetType
  logEventName?: ContentLogEventActionName
  logPageName?: ContentLogPageName
}

const REGEXP_OUT_LINK = /^\s?https?:\/\//
const OUT_LINK_REL = 'noopener noreferrer'

function AutoLinkText({
  className,
  href,
  onClick,
  pathname = RouterMap.CONTENT,
  target = AnchorTargetType.BLANK,
  outLink = false,
  innerLink = false,
  plainText = false,
  autoBlur = false,
  logEventName,
  logPageName,
  children,
  ...rest
}: PropsWithChildren<LinkButtonProps>) {
  const autoLinkRef = useRef<HTMLSpanElement | HTMLAnchorElement | null>(null)

  const maybeOutLink = REGEXP_OUT_LINK.test(href as string)
  const maybeInnerLink =
    !maybeOutLink && (typeof href === 'string' || typeof href === 'object')
  const innerLinkHref = typeof href === 'object' ? { pathname, ...href } : href

  const handleClick = useCallback(() => {
    trackEvent({ pageName: logPageName, actionName: logEventName })
    if (autoBlur) {
      autoLinkRef?.current?.blur?.()
    }
    onClick?.()
  }, [logPageName, logEventName, autoBlur, onClick])

  const autoLinkTextElementProps: any = {
    onClick: handleClick,
    ref: autoLinkRef,
    className,
    ...rest,
  }

  // seo 목적으로 숨김 텍스트 처리 추가
  // ex) "<hidden>페이지</hidden>자세히보기" 와 같은 링크 텍스트에서 중괄호 내의 텍스트는 히든처리
  const contents = (() => {
    const wrapChildren = isArray(children) ? children : [children]

    return wrapChildren
      .map((item) => {
        if (isString(item)) {
          return convertTextWithHidden(item)
        }

        return item
      })
      .flat()
  })()

  switch (true) {
    case !plainText && (maybeOutLink || outLink):
      return (
        <Styled.DefaultAnchor
          href={href as string}
          target={target}
          rel={OUT_LINK_REL}
          {...autoLinkTextElementProps}
        >
          {contents}
          {target === AnchorTargetType.BLANK && <Hidden>새창열림</Hidden>}
        </Styled.DefaultAnchor>
      )
    case !plainText && (maybeInnerLink || innerLink):
      return (
        <Link href={innerLinkHref} passHref>
          <Styled.DefaultAnchor {...autoLinkTextElementProps}>
            {contents}
          </Styled.DefaultAnchor>
        </Link>
      )
    case plainText:
    default:
      return (
        <Styled.DefaultButton {...autoLinkTextElementProps}>
          {contents}
        </Styled.DefaultButton>
      )
  }
}

const Styled = {
  DefaultAnchor: styled.a`
    text-decoration: none;
  `,
  DefaultButton: styled.button`
    margin: 0;
    padding: 0;
    font-family: inherit;
    border: 0 none;
    background: none transparent;
    cursor: pointer;
  `,
}

export default AutoLinkText
