import AutoLinkText from '@/components/AutoLinkText'
import { MOBILE_FOOTER_HEIGHT, PC_FOOTER_HEIGHT } from '@/constants/Layout'
import useAppContext from '@/contexts'
import { CategoryType } from '@/enums/MetaContent'
import useMetaContent from '@/hooks/fetch/useMetaContent'
import {
  FONT_14_BOLD,
  FONT_16_BOLD,
  STYLE_FONT_FAMILY,
  STYLE_FONT_SIZE,
  STYLE_FONT_WEIGHT,
  STYLE_KEEP_WORD,
  STYLE_LINE_HEIGHT,
} from '@/styles/fonts'
import { STYLE_CLEAR_FIX } from '@/styles/presets'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ComponentStyledOptionalProps } from '@/types/Component'
import { Hidden } from '@kakaomobility/tui-react'
import React from 'react'
import styled from 'styled-components'
import RenderOnly from '../RenderOnly'

interface LayoutFooterProps extends ComponentStyledOptionalProps {}

const Partial = {
  FooterCategoryLink({ items }) {
    return (
      <>
        {items?.map((item, childIndex) => {
          const key = `LayoutFooter-footerCategoryLink-${childIndex}`
          const { logPageName, logEventName, hash } = item

          const itemLinkProps = {
            href: item?.href ?? {
              query: { ...item?.query, contents: [item?.contentId] },
              hash,
            },
            outLink: item?.type === CategoryType.LINK,
            logPageName,
            logEventName,
          }
          return (
            <Styled.CategoryItem key={key}>
              <Styled.CategoryLink {...itemLinkProps}>
                {item?.label}
              </Styled.CategoryLink>
            </Styled.CategoryItem>
          )
        })}
      </>
    )
  },
  FooterCategoryMenu() {
    const { meta } = useMetaContent()
    const { categories } = meta
    return categories?.reduce((accumulator, item, index, array) => {
      if (item?.parentId || item?.type === CategoryType.RIGHT_LINK) {
        return accumulator
      }
      const linkProps = {
        items: array.filter(
          ({ parentId: childForParentId }) => childForParentId === item?.id
        ),
      }
      accumulator.push(
        <Styled.CategoryList key={`LayoutFooter-footerCategoryMenu-${index}`}>
          <Styled.CategoryTitle>{item?.label}</Styled.CategoryTitle>
          <Partial.FooterCategoryLink {...linkProps} />
        </Styled.CategoryList>
      )
      return accumulator
    }, [])
  },

  FooterLinkMenu() {
    const { noticeAction } = useAppContext()
    const { meta } = useMetaContent()
    const { links } = meta

    return (
      <Styled.FooterMenuWrap>
        {links.map((item, index) => {
          const key = `LayoutFooter-footerLinkMenu-${index}`

          const itemLinkProps = (() => {
            if (item?.href) {
              return { href: item?.href }
            }

            // TODO: 추후 targetModalId로 변수명 변경
            if (item?.target) {
              return {
                onClick: () => noticeAction.setNotice(item.target),
                'aria-haspopup': 'dialog' as const,
              }
            }

            if (item?.contentId) {
              return {
                href: {
                  query: { ...item?.query, contents: [item?.contentId] },
                },
              }
            }
          })()

          const Wrap =
            item?.style === 'bold'
              ? Styled.CategorySubBoldLink
              : Styled.CategorySubLink

          return (
            <Styled.LinkWrap key={key}>
              <Wrap {...itemLinkProps}>
                {item?.icon && (
                  <Styled.FooterLinkIcon src={item?.icon} alt='' />
                )}
                {item.label}
              </Wrap>
            </Styled.LinkWrap>
          )
        })}
      </Styled.FooterMenuWrap>
    )
  },
}

function LayoutFooter({ className }: LayoutFooterProps) {
  const { meta } = useMetaContent()
  const { address, corporation } = meta

  return (
    <Styled.Root className={className}>
      <Styled.ExtraContainer>
        <Styled.Row>
          <Hidden as='h3'>전체메뉴</Hidden>
          <Styled.FooterMenu>{Partial.FooterCategoryMenu()}</Styled.FooterMenu>
        </Styled.Row>
      </Styled.ExtraContainer>
      <Styled.DefaultContainer>
        <Styled.Row>
          <Hidden as='h3'>푸터 메뉴</Hidden>
          <Styled.RenderOnlyPc>
            <Styled.Cite>&copy; {corporation}</Styled.Cite>
            <Partial.FooterLinkMenu />
            <Styled.Address>{address}</Styled.Address>
          </Styled.RenderOnlyPc>
          <RenderOnly.Mobile>
            <Partial.FooterLinkMenu />
            <Styled.Address>{address}</Styled.Address>
            <Styled.Cite>&copy; {corporation}</Styled.Cite>
          </RenderOnly.Mobile>
        </Styled.Row>
      </Styled.DefaultContainer>
    </Styled.Root>
  )
}

const Styled = {
  Root: styled.footer`
    ${STYLE_KEEP_WORD};
    z-index: 1000;
    width: 100%;
    /* height: ${PC_FOOTER_HEIGHT}px; //고정 높이 수정 */
    box-sizing: border-box;
    padding-bottom: 34px;
    background-color: ${STYLE_COLOR.BLACK01};
    color: ${STYLE_COLOR.WHITE01};
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
    user-select: none;
    cursor: default;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      /* height: ${MOBILE_FOOTER_HEIGHT}px; // 고정 높이 수정 */
      min-height: ${MOBILE_FOOTER_HEIGHT}px;
      padding-bottom: 40px;
    }
  `,
  Fixer: styled.div`
    ${STYLE_CLEAR_FIX};
    max-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH};
    margin: 0 auto;
  `,
  ExtraContainer: styled(RenderOnly.Pc)`
    display: table;
    width: 100%;
    box-sizing: border-box;
  `,
  DefaultContainer: styled.div`
    ${STYLE_CLEAR_FIX};
    margin-top: 85px;
    text-align: center;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 0;
      padding-top: 40px;
      text-align: left;
    }
  `,
  Row: styled.div`
    text-align: left;
    position: relative;
    max-width: 1920px;
    min-width: 1200px;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    margin: 0 auto;
    padding: 0 5%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-width: 0;
      min-height: 0;
    }
  `,

  CategoryList: styled.dl`
    padding: 50px 0 0 0;
    width: 12.1%;
    max-width: 234px;
    @media (max-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH}) {
      width: 135px;
    }
    flex-shrink: 0;
  `,
  CategoryTitle: styled.dt`
    position: relative;
    margin-bottom: 16px;
    ${FONT_16_BOLD};

    & * {
      cursor: default !important;
    }
  `,
  CategoryItem: styled.dd`
    display: block;
    margin-top: 10px;

    &:first-of-type {
      margin-top: 0;
    }
  `,
  CategoryLink: styled(AutoLinkText)`
    position: relative;
    display: inline-block;
    background: none transparent;
    border: 0 none;
    font-size: ${STYLE_FONT_SIZE._14};
    line-height: ${STYLE_LINE_HEIGHT._20};
    color: ${STYLE_COLOR.WHITE02_60};
    text-decoration: none;
    cursor: pointer;
  `,

  FooterLinkIcon: styled.img`
    margin-right: 4px;
    width: 20px;
    height: 20px;
  `,

  Address: styled.address`
    font-size: ${STYLE_FONT_SIZE._14};
    line-height: ${STYLE_LINE_HEIGHT._20};
    color: ${STYLE_COLOR.WHITE01};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      padding-top: 10px;
      clear: both;
      font-size: ${STYLE_FONT_SIZE._12};
      color: ${STYLE_COLOR.BLACK03};
    }
  `,
  Cite: styled.cite`
    min-width: 16.66667%;
    font-size: ${STYLE_FONT_SIZE._14};
    line-height: ${STYLE_LINE_HEIGHT._20};
    color: ${STYLE_COLOR.WHITE01};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      padding-top: 8px;
      font-size: ${STYLE_FONT_SIZE._12};
      color: ${STYLE_COLOR.BLACK03};
    }
  `,

  FooterMenuWrap: styled.ul`
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  LinkWrap: styled.li`
    list-style: none;
  `,
  CategorySubLink: styled(AutoLinkText)`
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 16px;
    font-size: ${STYLE_FONT_SIZE._14};
    line-height: ${STYLE_LINE_HEIGHT._20};
    color: ${STYLE_COLOR.WHITE01};
    text-decoration: none;
    &.link {
      cursor: pointer;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-bottom: 20px;
      font-size: ${STYLE_FONT_SIZE._12};
      color: ${STYLE_COLOR.BLACK05};
    }
  `,
  CategorySubBoldLink: styled(AutoLinkText)`
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 16px;
    ${FONT_14_BOLD};
    line-height: ${STYLE_LINE_HEIGHT._20};
    color: ${STYLE_COLOR.WHITE01};
    text-decoration: underline;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-bottom: 20px;
      font-size: ${STYLE_FONT_SIZE._12};
      font-weight: ${STYLE_FONT_WEIGHT.BOLD};
      color: ${STYLE_COLOR.BLACK05};
    }
  `,
  FooterMenu: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  RenderOnlyPc: styled(RenderOnly.Pc)`
    display: flex;
  `,
}

export default LayoutFooter
